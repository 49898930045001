<!-- Modal to create a new billing batch run -->
<template>
  <v-dialog :value="value" persistent width="800">
    <v-card class="fill">
      <v-toolbar flat dark class="main">
        <v-toolbar-title> {{ readonly ? "Batch Details" : "New Batch Billing Run" }}  </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container class="ma-0 px-4" fluid>
        <v-form ref="form" lazy-validation>
          <!-- <v-toolbar flat class="fill">
              <v-toolbar-title>Batch Info</v-toolbar-title>
            </v-toolbar> -->
          <v-card-title class="pl-0 pt-0">Details</v-card-title>
          <v-row dense>
            <v-col v-if="isReversalOrRebill">
              <v-autocomplete
                v-model="targetBatch"
                :items="batches"
                item-text="name"
                return-object
                background-color="input"
                label="Batch to Reverse/Rebill*"
                required
                :loading="loading"
                :menu-props="{ maxHeight: 215 }"
                :rules="[v => !!v || 'Reversal/Rebill Batch is required']"
                outlined
                dense
                @change="reverseInfo">
              </v-autocomplete>
            </v-col>
            <v-col xl="7">
              <v-text-field
                v-model="batch.name"
                label="Name*"
                background-color="input"
                required
                :rules="[v => !!v || 'Name is required', validateText]"
                outlined
                :disabled="readonly"
                dense>  
              </v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="batch.responsible_party_id"
                label="Responsible Party*"
                :items="responsiblePartyOptions"
                background-color="input"
                :menu-props="{ offsetY: true }"
                item-text="name"
                item-value="id"
                :rules="[v => !!v || 'Responsible party is required']"
                outlined
                :disabled="readonly"
                dense>
              </v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-select
                v-model="batch.batch_type"
                label="Batch Type*"
                background-color="input"
                :items="batchTypes"
                :item-text="'kv_name'"
                :item-value="'kv_constant'"
                :menu-props="{ offsetY: true }"
                required
                :rules="[v => !!v || 'Batch type is required']"
                outlined
                :disabled="readonly"
                @change="batchTypeChanged"
                dense>
              </v-select>
            </v-col>
            <v-col>
              <v-menu
                v-model="start_dt_picker"
                transition="scale-transition"
                offset-y
                nudge-top="25"
                max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="batch.start_date"
                    label="Start Date"
                    ref="start_date"
                    type="date"
                    prepend-inner-icon="mdi-calendar"
                    dense
                    background-color="input"
                    outlined
                    v-bind="attrs"
                    v-on="!isReversalOrRebill ? on : null"
                    :disabled="isReversalOrRebill || readonly"
                    :rules="[isValidStartDate]"
                    @input="batch.end_date ? $refs.end_date.validate() : null">
                  </v-text-field>
                </template>
                <v-date-picker 
                  v-model="batch.start_date"
                  :max="batch.end_date"
                  no-title>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                v-model="end_dt_picker"
                transition="scale-transition"
                offset-y
                nudge-top="25"
                max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="batch.end_date"
                    label="End Date"
                    ref="end_date"
                    type="date"
                    prepend-inner-icon="mdi-calendar"
                    dense
                    background-color="input"
                    outlined
                    v-bind="attrs"
                    v-on="!isReversalOrRebill ? on : null"
                    :disabled="isReversalOrRebill || readonly"
                    :rules="[isValidEndDate]"
                    @input="batch.start_date ? $refs.start_date.validate() : null">
                  </v-text-field>
                </template>
                <v-date-picker 
                  v-model="batch.end_date"
                  :min="batch.start_date"
                  :show-current="batch.start_date || true"
                  no-title>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-divider />
          <v-card-title class="pl-0">Filters</v-card-title>
          <v-row dense>
            <v-col>
              <v-select
                v-model="filterType"
                :items="filters"
                label="Filter Type"
                item-text="text"
                item-value="value"
                outlined
                mulitple
                dense
                background-color="input"
                prepend-inner-icon="mdi-filter-outline"
                :menu-props="{ offsetY: true }"
                :disabled="readonly">
              </v-select>
            </v-col>
            <v-col cols="8">
              <v-autocomplete
                v-if="filterType === 'promo'"
                v-model="selected"
                auto-select-first
                item-text="promo_name"
                item-value="promo_id"
                :items="promos"
                :loading="loadingItems"
                :hint="inputHint"
                :persistent-hint="!searchFields"
                :menu-props="{ maxHeight: 215, maxWidth: 650 }"
                background-color="input"
                small-chips
                clearable
                dense
                label="Select"
                multiple
                outlined
                :disabled="readonly">
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    small
                    style="max-width: 90%"
                    @click="data.select"
                    @click:close="remove(data.item.promo_id)">
                    <span class="text-truncate">
                      {{ data.item.promo_name }}
                    </span>
                  </v-chip>
                </template>
                <template v-slot:item="{ item }">
                  <template>
                    <v-checkbox class="mr-2" />
                    <v-list-item-content>
                      <v-list-item-subtitle class="primary--text">
                        Promo Name
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ item.promo_name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="primary--text">
                        Promo Number
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ item.promo_number }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="primary--text">
                        Promo Dates
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ formatDate(item.start_dt || item.promo_start_dt) }}
                        ~ {{ formatDate(item.end_dt || item.promo_end_dt) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <v-autocomplete 
                v-if="readonly && filterType !== 'promo'"
                :value="activeFilter.items"
                :items="activeFilter.items"
                :item-value="activeFilter.value" 
                :item-text="activeFilter.text"
                :loading="loadingItems"
                background-color="input"
                label="Filter*"
                required
                outlined
                multiple
                :disabled="readonly"
                dense>
              </v-autocomplete>
              <v-autocomplete
                v-if="!readonly && filterType !== 'promo'"
                v-model="selected"
                :label="(isPartyFilterActive && !isReversalOrRebill) ? 'Search' : 'Select'"
                single-line
                auto-select-first
                :item-text="activeFilter.text"
                :item-value="activeFilter.value"
                :items="activeFilter.items"
                :loading="loadingItems"
                :menu-props="{ maxHeight: 215, maxWidth: 650 }"
                :search-input.sync="search"
                :hint="isPartyFilterActive && isReversalOrRebill ? inputHint : undefined"
                :persistent-hint="isPartyFilterActive && isReversalOrRebill && !batch.reversal_batch_id"
                :no-filter="isPartyFilterActive"
                hide-no-data
                background-color="input"
                small-chips
                deletable-chips
                clearable
                dense
                multiple
                outlined
                :disabled="readonly">
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider v-if="!readonly" />
      <v-card-actions>
      <v-row dense>
        <v-col cols="auto" class="ml-auto mt-1">
        <v-tooltip top v-if='overlap > 0 && !readonly'>
            <template #activator="{ on }">
              <v-icon v-on='on' dense color='red'>mdi-alert-decagram-outline</v-icon>
            </template>
          <span>Promotion Overlap: {{overlap}}</span>
        </v-tooltip>
        </v-col>
        <v-col cols="auto">
          <v-btn 
            v-if="!readonly"
            color="success"
            @click="createBatch" 
            :loading="saving"
            :disabled="saving">
            Start
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
// api
import Search from '@/axios/search-endpoint'
// mixins
import { dateFormat } from '@/mixins/date-format'
import { displayAlert } from '@/mixins/displayAlert'
import { validation } from "@/mixins/validation";
import { utils } from '@/mixins/utils'
import { userAccess } from '@/mixins/user-access'
//libraries
import { debounce, sortBy } from 'lodash'
export default {
  data () {
    return {
      loading: false,
      loadingItems: false,
      saving: false,
      start_dt_picker: false,
      end_dt_picker: false,
      validParties: [],
      batches: [],
      batch: {
        responsible_party_id: null,
        start_date: null,
        end_date: null,
        env: process.env.NODE_ENV === 'production' ? 'prod' : 'dev',
        user_id: null,
        name: null,
        batch_time: new Date().toJSON().slice(0, -1),
        status: 'PENDING',
        last_update_date: new Date().toJSON().slice(0, -1),
        batch_type: 'STANDARD',
        promo_ids: [],
        reversal_batch_id: null,
        party_id: { parties: [] },
        party_type: null,
        promo_types: [],
        note: ''
      },
      targetBatch: {
        id: null
      },
      overlap: 0,
      parties: [],
      filterType: '',
      promos: [],
      stores: [],
      allStores: [],
      noteStatus: false,
      batchAdGroups: [],
      selectedAdGroups: [],
      selected: [],
      search: ''
    }
  },
  name: 'BatchModal',
  props: ['value', 'selectedBatch'],
  mixins: [dateFormat, displayAlert, utils, validation, userAccess],
  watch: {
    filterType: {
      handler (newValue) {
        if (newValue) {
          this.selected = []
          if (newValue === 'promo' && !this.isReversalOrRebill) {
            this.promos = []
            return this.getBillablePromos()
          }
          if (this.parties.length > 0 && !this.isReversalOrRebill) {
            this.parties = []
          }
        }
      }
    },
    searchFields: {
      handler (newValue) {
        if (newValue === true) {
          this.getPromoOverlap()
        }
      }
    },
    search: {
      handler: debounce(function (newValue) {
        if (newValue && !this.isReversalOrRebill) {
          if (this.isPartyFilterActive) {
            return this.searchParties(newValue)
          }
        }
      }, 500)
    },
    'batch.start_date': {
      handler (newValue) {
        if (newValue) {
          this.batchDateChanged()
        }
      }
    },
    'batch.end_date': {
      handler (newValue) {
        if (newValue) {
          this.batchDateChanged()
        }
      }
    },
    isReversalOrRebill: {
      handler (newValue) {
        if (newValue === false) {
          this.batch.reversal_batch_id = null
          this.targetBatch = { id: null }
          this.batch.start_date = null
          this.batch.end_date = null
          this.overlap = 0
          this.clearFilters()
        }
      }
    }
  },
  async created () {
    if (this.responsiblePartyOptions.length === 1) {
      this.batch.responsible_party_id = this.responsiblePartyOptions[0].id
    }
    this.$store.dispatch('getPromoCategories')
    this.$store.dispatch('getBatchTypes')
    if (this.selectedBatch?.id) {
      this.targetBatch.id = this.selectedBatch.id
      this.batch = { ...this.batch, ...this.selectedBatch}
      if (this.selectedBatch.batch_type == 'SCAN') {
        this.batch.batch_type = 'STANDARD'
      }
      this.filterType = this.getFilterType(this.batch?.party_type)
      if (['store', 'ad_group'].includes(this.filterType)) {
        await this.getBatchPartyData()
      }
    }
  },
  computed: {
    batchTypes () {
      return this.$store.getters.batchTypes;
    },
    filters () {
      return [
        { text: 'Ad Groups', value: 'ad_group', show: true}, 
        { text: 'Distribution Center', value: 'distribution_center', show: this.$auth.tenant === 'alliance-retail-group' },
        { text: 'Stores', value: 'store', show: true },
        { text: 'Promo Categories', value: 'promo_type', show: true }
      ].filter( item => item.show)
    },
    readonly () {
      return Boolean(this.selectedBatch?.id)
    },
    responsiblePartyOptions () {
      if (this.responsibleParties.length > 0) {
        if (this.$auth.tenant === 'iprosystems') {
          this.responsibleParties.filter(party => !['JB Gottstein','C&S Wholesale Grocers','NTC Wholesaler','Piggly Wiggly Alabama Distributing Co., Inc.'].includes(party.name))
        }
        return this.responsibleParties
      }
      return []
    },
    isReversalOrRebill () {
      return ['REVERSAL', 'REBILL'].includes(this.batch.batch_type)
    },
    isPartyFilterActive () {
      return ['store'].includes(this.filterType)
    },
    activeFilter () {
      if (this.filterType) {
        return this.filterOptions[this.filterType]
      }
      return { text: '', value: '', items: [] }
    },
    adGroupsByTenant() {
      if (this.targetBatch.id) {
        return this.batchAdGroups
      }
      if (this.$auth.tenant === 'pricechopper') {
        return this.adGroups.filter(group => group.attributes.AD_GROUP_BILLING != null && group.attributes.AD_GROUP_BILLING === "True")
      } 
      return sortBy(this.adGroups.filter(group => group.attributes.AD_GROUP_IS_ACTIVE === "True"), 'party_name')
    },
    filterOptions () {
      return { 
        promo: {
          items: this.promos,
          text: 'promo_name',
          value: 'promo_id',
          field: 'promo_ids'
        },
        promo_type: {
          items: this.currentCategories,
          text: 'name',
          value: 'id',
          field: 'promo_categories'
        },
        ad_group: {
          items: this.adGroupsByTenant,
          text: 'party_name',
          value: 'party_id',
          field:  'party_id',
          storeNumberAttribute: 'WHOLESALER_STORE_NUMBER',
          party_type: 2
        },
        distribution_center: {
          items: this.distributionCenters,
          text: 'party_name',
          value: 'party_id',
          field: 'party_id',
          storeNumberAttribute: 'WHOLESALER_STORE_NUMBER',
          party_type: 3
        },
        store: {
          items: (this.targetBatch.id ? this.stores : this.parties),
          text: 'party_name',
          value: 'party_id',
          field: 'party_id',
          storeNumberAttribute: 'WHOLESALER_STORE_NUMBER',
          party_type: 1
        }
      }
    },
    filterText () {
      return this.filters.find(filter => {
        return filter.value === this.filterType
      }).text
    },
    adGroups () {
      return this.userAdGroups
    },
    distributionCenters () {
      return this.$store.getters.distributionCenters
        .toSorted((a, b) => {
          if (a.party_name < b.party_name) return -1;
          if (a.party_name > b.party_name) return 1;
          return 0;
        });
    },
    promoCategories () {
      return this.$store.getters.promoCategories
    },
    responsibleParties () {
      return this.$store.getters.responsibleParties
    },
    searchFields () {
      return (!!this.batch.responsible_party_id && !!this.batch.start_date && !!this.batch.end_date)
    },
    currentCategories () {
      const { batch_type } = this.batch
      return this.promoCategories.filter(cat => {
        if (batch_type === 'STANDARD') {
          return cat.scan_promotion
        } else if (batch_type === 'BILLBACK') {
          return cat.billback_promotion
        }
        return cat
      })
    },
    inputHint () {
      if (!this.searchFields) {
        return this.isReversalOrRebill 
          ? 'Please select a batch to reverse/rebill'
          : 'Please select a start and end date'
      }
      return undefined
    }
  },
  methods: {
    remove (id) {
      const index = this.selected.indexOf(id)
      if (index >= 0) this.selected.splice(index, 1)
    },
    batchTypeChanged () {
      if (this.isReversalOrRebill && !this.targetBatch.id) {
        this.batch.start_date = null
        this.batch.end_date = null
        this.clearFilters()
        this.getBatches()
      }
    },
    batchDateChanged () {
      if (this.filterType === 'promo' && !this.isReversalOrRebill) {
        this.selected = []
        this.promos = []
        this.getBillablePromos()
      }
    },
    clearFilters () {
      this.selected = []
      this.promos = []
      this.parties = []
      this.stores = []
      this.adGroups = []
    },
    async getBillablePromos () {
      const { batch_type, start_date, end_date } = this.batch
      if (!start_date || !end_date || this.isReversalOrRebill) return

      this.loadingItems = true
      let promos = []
      try {
        const res = await this.$Promo.getBillablePromos(batch_type, start_date, end_date)
        if (res?.data?.length > 0) {
          promos = res.data
        }
        this.promos = promos
      } catch (error) {
        this.handleError(error)
      } finally {
        this.loadingItems = false
      }
    },
    async searchParties (term) {
      if (!term) return
      this.loadingItems = true
      try {
        let previouslySelected = this.parties.filter(party => this.selected.some(selectedParty => party.party_id.includes(selectedParty)))
        let { data } = await Search.customerSearch(term)
        if (data?.length > 0) {
          data = this.prependStoreNumber(data.filter(party => party.party_type.toLowerCase() === this.filterType))
        }
        this.parties = [...previouslySelected, ...data]
      } catch (error) {
        this.handleError(error)
      } finally {
        this.loadingItems = false
      }
    },
    prependStoreNumber (data) {
      return data.map(item => {
        const storeNumber = item.attributes[this.activeFilter.storeNumberAttribute]
        item[this.activeFilter.text] = storeNumber ? `#${storeNumber} ${item[this.activeFilter.text]}` : `${item[this.activeFilter.text]}`
        return item;
      })
    },
    addNote () {
      this.noteStatus = true
    },
    getFilterType (party_type) {
      if (party_type == 1){
        return "store"
      } else if (party_type == 2) {
        return "ad_group"
      } else if (this.selectedBatch.promo_categories) {
        return "promo_type"
      } else if (this.selectedBatch.promo_ids?.length > 0) {
        return 'promo'
      } else {
        return null
      }
    },
    async getBatches () {
      this.loading = true
      let batches = []
      try {
        const res = await this.$BillingBatch.getList()
        if (res?.data) {
          batches = res.data.filter(batch => batch.status === 'COMPLETE')
        }
        this.batches = batches
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    async getBatchPartyData () {
      this.loadingItems = true
      const partyIds = this.batch.party_id.parties.map(party => party.party_id)
      const searchTerms = this.putPartyIdsInGroups(partyIds)

      try {
        let data = await this.batchIdSearch(searchTerms, "or")
        if (this.filterType === 'store'){
          this.stores = data
        } else {
          this.batchAdGroups = data
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loadingItems = false
      }
    },
    putPartyIdsInGroups (idList) {
      const maxSearchSize = 250   // Will fail if above 275
      let searchTerms = new Array()

      // If just passing in one string value (usually a default value), then return an array of just that value
      if (typeof(idList) == "string")
        return [idList]

      // Else, break up the array into smaller batches and pass that back
      let groups = Math.ceil(idList.length / maxSearchSize)
      for (let x = 0; x < groups; x++)
      {
        let startIndex = x * maxSearchSize
        let endIndex = (x + 1) * maxSearchSize
        searchTerms.push(idList.slice(startIndex, endIndex).join(' '))
      }

      return searchTerms
    },
    async batchIdSearch (searchTerms, operator = "or") {
      try {
        const searches = searchTerms.map(searchTerm => {
            return Search.customerSearch(searchTerm, operator)
        })

        const { fulfilled, rejected } = await this.getAllSettled(searches, true)
        if (rejected.length > 0)
          throw rejected

        const results = fulfilled.flatMap(result => result.data)
        return results
      }
      catch (err) {
      this.handleError(err)
      }
    },
    async createBatch () {
      const isValid = this.validate()
      if (!isValid) {
        return
      }
      const payload = this.buildBatchPayload()
      this.saving = true
      try {
        const res = await this.$BillingBatch.post(payload)
        if (res?.status === 201) {
          this.emitAlert(true, 'success', 'Successfully started new batch billing run')
          this.$emit('close', true)
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.saving = false
       }
    },
    buildBatchPayload () {
      const batch = {
        ...this.batch,
        name: this.batch.name.trim(),
        user_id: this.$auth.user.email,
      }
      if (!this.isReversalOrRebill) {
        batch.reversal_batch_id = null
      }
      if (this.filterType && this.selected.length > 0) {
        let value = this.selected
        if (this.activeFilter.field === 'party_id') {
          // why is the API model like this?  I'm sorry, I didn't know ;_; - Anthony
          value = {
            parties: this.selected.map(party_id => {
              return { party_id }
            })
          }
          batch.party_type = this.activeFilter.party_type
        }
        batch[this.activeFilter.field] = value
      }
      return batch
    },
    validate () {
      return this.$refs.form.validate()
    },
    close () {
      this.$emit('close')
    },
    async reverseInfo () {
      this.clearFilters()
      const { id, start_date, end_date, responsible_party_id } = this.targetBatch
      this.batch.start_date = start_date
      this.batch.end_date = end_date
      this.batch.responsible_party_id = responsible_party_id
      this.batch.reversal_batch_id = id
      
      this.loadingItems = true
      const promises = [
        this.getBatchPromos(id),
        this.getBatchStores(id),
        this.getBatchAdGroups(id)
      ]
      try {
        const { rejected } = await this.getAllSettled(promises, true)
        if (rejected.length > 0) throw rejected
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loadingItems = false
      }
    },
    async getBatchPromos (batch_id) {
      const res = await this.$BillingBatch.getBatchPromos(batch_id)
      this.promos = res?.data || []
    },
    async getBatchStores (batch_id) {
      const res = await this.$BillingBatch.getBatchStores(batch_id)
      this.stores = res?.data || []
    },
    async getBatchAdGroups (batch_id) {
      const res = await this.$BillingBatch.getBatchAdGroups(batch_id)
      this.batchAdGroups = res?.data || []
    },
    async getPromoOverlap () {
      const params = {
        start_dt: this.batch.start_date,
        end_dt: this.batch.end_date
      }
      await this.$BillingBatch
        .getPromoOverlap(params)
        .then(res => {
          if (res.data) {
            this.overlap = res.data.overlap
          }
        })
        .catch(err => {
          this.handleError(err)
        })
    },
    isValidStartDate (value) {
      if (value) {
        if (!this.isValidYear(value)) {
          this.start_dt_picker = false
          return 'Invalid year value'
        }
        if (this.batch.end_date) {
          const validDates = this.validateDateRange()
          if (!validDates) {
            this.start_dt_picker = false
            return 'Invalid date range'
          }
        }
        return true
      }
      if(this.isReversalOrRebill){
        return true
      }
      return 'Start Date is required'
    },
    isValidEndDate (value) {
      if (value) {
        if (!this.isValidYear(value)) {
          this.end_dt_picker = false
          return 'Invalid year value'
        }
        if (this.batch.start_date) {
          const validDates = this.validateDateRange()
          if (!validDates) {
            this.end_dt_picker = false
            return 'Invalid date range'
          }
        }
        return true
      }
      if(this.isReversalOrRebill){
        return true
      }
      return 'End Date is required'
    },
    isValidYear (value) {
      if (value) {
        let [year] = value.split('-')
        year = Number(year)
        const currentYear = new Date().getFullYear()
        return (year > currentYear - 3) && (year < currentYear + 3)
      }
      return false
    },
    validateDateRange () {
      const { start_date, end_date } = this.batch
      const startDate = new Date(start_date)
      const endDate = new Date(end_date)
      return (endDate >= startDate)
    },
  }
}
</script>
<style>
.v-text-field__slot input::-webkit-calendar-picker-indicator {
  display: none;
}

.v-text-field__slot input[type="date"] {
  cursor: text;
}

.v-text-field__slot input[type="date"]::-webkit-input-placeholder {
  visibility: hidden;
}
</style>